































































import { Vue, Component } from 'vue-property-decorator'

@Component
export default class OurFocus extends Vue {
  icons = ['mdi-account-group-outline', 'mdi-update', 'mdi-shield-outline']
}
