










import { Component, Vue } from 'vue-property-decorator'
import Welcome from '@/components/home/Welcome.vue'
import AboutUs from '@/components/home/AboutUs.vue'
import OurFocus from '@/components/home/OurFocus.vue'
import FamilyStats from '@/components/home/FamilyStats.vue'
import ContactUs from '@/components/common/ContactUs.vue'

@Component({
  components: {
    Welcome,
    OurFocus,
    FamilyStats,
    AboutUs,
    ContactUs
  }
})
export default class Home extends Vue {}
