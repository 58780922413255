












































import { Vue, Component } from 'vue-property-decorator'
import VueRecaptcha from 'vue-recaptcha'
import { required, email, max } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import VueI18n from 'vue-i18n'

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} can not be empty'
})

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters'
})

extend('email', {
  ...email,
  message: 'Email must be valid'
})

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    VueRecaptcha
  }
})
export default class ContactUs extends Vue {
  enquiry = { name: '', email: '', phone: '', message: '' }
  formMessage: VueI18n.TranslateResult = ''
  formMessageType = ''
  errors = []
  valid = true
  lazy = true

  submit () {
    this.formMessage = ''
    this.formMessageType = ''

    // @ts-ignore
    this.$refs.observer.validate().then((isValid: boolean) => {
      if (isValid) {
        // @ts-ignore
        this.$refs.invisibleRecaptcha.execute()
      }
    })
  }

  onVerify (response: any) {
    this.formMessage = ''
    this.formMessageType = ''

    // @ts-ignore
    this.$refs.observer.validate().then((isValid: boolean) => {
      if (isValid) {
        console.log('Verify: ' + response)
        const api = '/enquiry'
        this.axios.post(api, {
          header: 'enquiry from mrwest.co.nz',
          reToken: response,
          content: `
          name: ${this.enquiry.name}
          email: ${this.enquiry.email}
          phone: ${this.enquiry.phone}
          message: ${this.enquiry.message}
          `
        }).then(() => {
          this.clear()
          this.formMessage = this.$t('message.success.messageSent')
          this.formMessageType = 'success'
        }).catch((e) => {
          this.formMessage = e
          this.formMessageType = 'error'
        })
      }
    })
  }

  clear () {
    this.formMessage = ''
    this.formMessageType = ''
    this.enquiry = { name: '', email: '', phone: '', message: '' }
    // @ts-ignore
    this.$refs.observer.reset()
  }
}
