























import Vue from 'vue'
import Footer from '@/components/common/Footer.vue'

export default Vue.extend({
  name: 'App',
  components: {
    Footer
  },
  data: () => ({
    //
  }),
  computed: {
    currentRouteName () {
      return this.$route.name
    }
  }
})
