

























import { Vue, Component } from 'vue-property-decorator'

@Component
export default class FamilyStats extends Vue {
  stats = [
    ['3', 'people'],
    ['5', 'Alpacas'],
    ['9', 'Chickens'],
    ['4.06h', 'lifestyle land']
  ]
}
